import React from 'react';
import ViewSplitPage from '../../components/splitpage/ViewSplitPage';
import './stylesBenefits.scss';

export default function ViewPrecinct () {
    
    const image = <img className='image benefits--image' src={ process.env.PUBLIC_URL + '/imgs/building-highlights/precinct.jpg' } alt='' />;

    const page = (
        <div className='copy-container benefits--copy-container'>
            <h1 className='title benefits--title'>The Collins Street Precinct</h1>
            <p className='statement benefits--statement'>Perfect mix of cafe culture, fine dining and cocktail bars</p>
            <p className='statement benefits--statement'>High-end retail and boutique hotel</p>
            <p className='statement benefits--statement'>Fitzroy, Treasury Gardens and Parliament House to the east</p>
            <p className='statement benefits--statement'>Recreation and entertainment venues within easy reach</p>
        </div>
    );
    
    return (
        <ViewSplitPage
            className='benefits--transform-container benefits--split-page'
            pageLeft={ page }
            pageRight={ image } />
    );
}