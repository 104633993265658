import React from 'react';
import ViewSlider from '../../components/slider/ViewSlider';
import ViewSplitPage from '../../components/splitpage/ViewSplitPage';
import data from '../../data/projectPillars';
import './stylesBenefits.scss';

function ProjectPillar( { videoSrc, pageContents, reverse } ) {
    const video = (
        <div className='project-pillars--video-container'>
            <video autoPlay muted>
                <source src={ process.env.PUBLIC_URL + videoSrc }/>
            </video>
        </div>
    );

    return (
        <ViewSplitPage 
            className={ `split-page--square-${ !reverse ? 'left' : 'right' } project-pillars` }
            pageLeft={ !reverse ? video: pageContents }
            pageRight={ !reverse ? pageContents : video } />
    );
}

export default function ViewProjectPillars () {
    const slideList = [];
    const settings = {
        arrows: true,
        fade: true,
        speed: 500,
        infinite: true,
        // beforeChange: () => console.log('test'),
        beforeChange: (prevIdx, nextIdx) => {
            const player = document.querySelectorAll('.project-pillars--video-container video')[nextIdx];
            player.currentTime = 0;
            player.play();
        },
    };

    for (let idx=0; idx<data.length; idx++)
    {
        const { video, pageContent, reverse } = data[idx];
        slideList.push({
            type: 'raw',
            slide: (<ProjectPillar videoSrc={ video } pageContents={ pageContent } reverse={ reverse } />)
        });
    }


    return (
        <div className='project-pillars--container'>
            <ViewSlider data={ { slideList: slideList, sliderOptions: settings } } />
        </div>
    );

}