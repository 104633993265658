import { highrise, lobby3D, lowrise, midrise } from "./floorplans";

const HIGH_RISE = 'HIGH-RISE';
const MID_RISE = 'MID-RISE';
const LOW_RISE = 'LOW-RISE';
const LOBBY = 'LOBBY';
const END_OF_TRIP = 'END-OF-TRIP';
const SIGNAGE = 'SIGNAGE';
const LIGHTS_ON = 'LIGHTS ON';
const LIGHTS_OFF = 'LIGHTS OFF';

export const data = {
    rotationHotspots: {
        0: [
            { title: HIGH_RISE, left: '1260px', top: '50px' },
            { title: MID_RISE, left: '1260px', top: '260px' },
            { title: LOW_RISE, left: '1260px', top: '470px' },
            { title: LOBBY, left: '1260px', top: '680px' },
            { title: END_OF_TRIP, left: '1260px', top: '840px' },
        ],
        18: [
            { title: HIGH_RISE, left: '1260px', top: '50px' },
            { title: MID_RISE, left: '1260px', top: '260px' },
            { title: LOW_RISE, left: '1260px', top: '470px' },
            { title: LOBBY, left: '1260px', top: '680px' },
            { title: END_OF_TRIP, left: '1260px', top: '840px' },
        ],
        36: [
            { title: HIGH_RISE, left: '1260px', top: '50px' },
            { title: MID_RISE, left: '1260px', top: '260px' },
            { title: LOW_RISE, left: '1260px', top: '470px' },
            { title: LOBBY, left: '1260px', top: '680px' },
            { title: END_OF_TRIP, left: '1260px', top: '840px' },
        ],
        54: [
            { title: HIGH_RISE, left: '1260px', top: '50px' },
            { title: MID_RISE, left: '1260px', top: '260px' },
            { title: LOW_RISE, left: '1260px', top: '470px' },
            { title: LOBBY, left: '1260px', top: '680px' },
            { title: END_OF_TRIP, left: '1260px', top: '840px' },
        ]
    },
    rotationLightingHotspots: {
        0: [
            { title: SIGNAGE, behaviour: 'independant-section', section: 'SIGN', left: '500px', top: '50px' },
            { title: LIGHTS_ON, behaviour: 'all-on', left: '500px', top: '120px' },
            { title: LIGHTS_OFF, behaviour: 'all-off', left: '500px', top: '190px' },
        ],
        18: [
            { title: SIGNAGE, behaviour: 'independant-section', section: 'SIGN', left: '500px', top: '50px' },
            { title: LIGHTS_ON, behaviour: 'all-on', left: '500px', top: '120px' },
            { title: LIGHTS_OFF, behaviour: 'all-off', left: '500px', top: '190px' },
        ],
        36: [
            { title: SIGNAGE, behaviour: 'independant-section', section: 'SIGN', left: '500px', top: '50px' },
            { title: LIGHTS_ON, behaviour: 'all-on', left: '500px', top: '120px' },
            { title: LIGHTS_OFF, behaviour: 'all-off', left: '500px', top: '190px' },
        ],
        54: [
            { title: SIGNAGE, behaviour: 'independant-section', section: 'SIGN', left: '500px', top: '50px' },
            { title: LIGHTS_ON, behaviour: 'all-on', left: '500px', top: '120px' },
            { title: LIGHTS_OFF, behaviour: 'all-off', left: '500px', top: '190px' },
        ]
    },
    menuGroups: {
        'HIGH-RISE': { 
            'LEVELS': HIGH_RISE,
            'FLY-THROUGH': 'https://vimeo.com/738800452/4092f353fe',
            'VIRTUAL TOUR': '/tours/VR_TOUR_HIGH-RISE/index.htm',
            'CGI': [
                '/imgs/gallery/gallery_image_9_enlarge.jpg', 
                '/imgs/gallery/gallery_page2_image_6_enlarge.jpg',
                '/imgs/gallery/gallery_page2_image_8_enlarge.jpg',
                '/imgs/gallery/gallery_page2_image_7_enlarge.jpg',
                '/imgs/gallery/gallery_page2_image_10_enlarge.jpg',
                '/imgs/gallery/gallery_page2_image_9_enlarge.jpg'
            ],
            'FLOORPLANS': highrise,
            'PANORAMA': '/tours/BUILDING_HIGH-RISE/index.htm',
        },
        'MID-RISE': { 
            'LEVELS': MID_RISE,
            'FLOORPLANS': midrise,
            'PANORAMA': '/tours/BUILDING_MID-RISE/index.htm',
        },
        'LOW-RISE': { 
            'LEVELS': LOW_RISE,
            'FLOORPLANS': lowrise,
        },
        'LOBBY': {
            'FLY-THROUGH': 'https://vimeo.com/738801066/b0825b6a87',
            'VIRTUAL TOUR': '/tours/VR_TOUR_LOBBY/index.htm',
            'CGI': [
                '/imgs/gallery/gallery_image_8_enlarge.jpg', 
                '/imgs/gallery/gallery_page2_image_1_enlarge.jpg',
                '/imgs/gallery/gallery_image_4_enlarge.jpg',
                '/imgs/gallery/gallery_page2_image_5_enlarge.jpg',
                '/imgs/gallery/gallery_page2_image_2_enlarge.jpg',
                '/imgs/gallery/gallery_page2_image_3_enlarge.jpg',
                '/imgs/gallery/gallery_page2_image_4_enlarge.jpg',
                '/imgs/gallery/gallery_lobby_cafe.jpg',
            ],
            'FLOORPLANS': lobby3D,
        },
        'END-OF-TRIP': {
            'FLY-THROUGH': 'https://vimeo.com/738800577/c6a9f8ccef',
            'CGI': [
                '/imgs/gallery/gallery_image_3_enlarge.jpg',
                '/imgs/gallery/gallery_page2_image_11_enlarge.jpg',
                '/imgs/gallery/gallery_page2_image_12_enlarge.jpg',
            ],
        },
    },
    rotationLevelGroups: {
        0: [
            { title: HIGH_RISE, left: '1475px', top: '50px' },
            { title: MID_RISE, left: '1475px', top: '260px' },
            { title: LOW_RISE, left: '1475px', top: '470px' },
        ],
        18: [
            { title: HIGH_RISE, left: '1475px', top: '50px' },
            { title: MID_RISE, left: '1475px', top: '260px' },
            { title: LOW_RISE, left: '1475px', top: '470px' },
        ],
        36: [
            { title: HIGH_RISE, left: '1475px', top: '50px' },
            { title: MID_RISE, left: '1475px', top: '260px' },
            { title: LOW_RISE, left: '1475px', top: '470px' },
        ],
        54: [
            { title: HIGH_RISE, left: '1475px', top: '50px' },
            { title: MID_RISE, left: '1475px', top: '260px' },
            { title: LOW_RISE, left: '1475px', top: '470px' },
        ]
    },
    levelGroups: {
        'HIGH-RISE': [ 37, 36, 35, 34, 33, 32, 31, 30, 29, 28, 27, 26, 25, 24, 23, 22, 21 ],
        'MID-RISE': [ 20, 19, 18, 17, 16, 15, 14, 13, 12, 11, 10, 9 ],
        'LOW-RISE': [ 8, 7, 6, 5, 4, 3, 2, 1 ],
    },
    rotationOverlays: {
        0: {
            'HIGH-RISE': { src: '/imgs/building/highlights/frame_00_highlight_highrise.png', top: 0, left: 0 },
            'MID-RISE': { src: '/imgs/building/highlights/frame_00_highlight_midrise.png', top: 0, left: 0 },
            'LOW-RISE': { src: '/imgs/building/highlights/frame_00_highlight_lowrise.png', top: 0, left: 0 },
            'LOBBY': { src: '/imgs/building/highlights/frame_00_highlight_lobby.png', top: 0, left: 0 },
            'SIGNAGE': { src: '/imgs/building/highlights/frame_00_highlight_sign.png', top: 0, left: 0 },
            37: { src: '/imgs/building/highlights/frame_00_highlight_level_37.png', top: 0, left: 0 },
            36: { src: '/imgs/building/highlights/frame_00_highlight_level_36.png', top: 0, left: 0 },
            35: { src: '/imgs/building/highlights/frame_00_highlight_level_35.png', top: 0, left: 0 },
            34: { src: '/imgs/building/highlights/frame_00_highlight_level_34.png', top: 0, left: 0 },
            33: { src: '/imgs/building/highlights/frame_00_highlight_level_33.png', top: 0, left: 0 },
            32: { src: '/imgs/building/highlights/frame_00_highlight_level_32.png', top: 0, left: 0 },
            31: { src: '/imgs/building/highlights/frame_00_highlight_level_31.png', top: 0, left: 0 },
            30: { src: '/imgs/building/highlights/frame_00_highlight_level_30.png', top: 0, left: 0 },
            29: { src: '/imgs/building/highlights/frame_00_highlight_level_29.png', top: 0, left: 0 },
            28: { src: '/imgs/building/highlights/frame_00_highlight_level_28.png', top: 0, left: 0 },
            27: { src: '/imgs/building/highlights/frame_00_highlight_level_27.png', top: 0, left: 0 },
            26: { src: '/imgs/building/highlights/frame_00_highlight_level_26.png', top: 0, left: 0 },
            25: { src: '/imgs/building/highlights/frame_00_highlight_level_25.png', top: 0, left: 0 },
            24: { src: '/imgs/building/highlights/frame_00_highlight_level_24.png', top: 0, left: 0 },
            23: { src: '/imgs/building/highlights/frame_00_highlight_level_23.png', top: 0, left: 0 },
            22: { src: '/imgs/building/highlights/frame_00_highlight_level_22.png', top: 0, left: 0 },
            21: { src: '/imgs/building/highlights/frame_00_highlight_level_21.png', top: 0, left: 0 },
            20: { src: '/imgs/building/highlights/frame_00_highlight_level_20.png', top: 0, left: 0 },
            19: { src: '/imgs/building/highlights/frame_00_highlight_level_19.png', top: 0, left: 0 },
            18: { src: '/imgs/building/highlights/frame_00_highlight_level_18.png', top: 0, left: 0 },
            17: { src: '/imgs/building/highlights/frame_00_highlight_level_17.png', top: 0, left: 0 },
            16: { src: '/imgs/building/highlights/frame_00_highlight_level_16.png', top: 0, left: 0 },
            15: { src: '/imgs/building/highlights/frame_00_highlight_level_15.png', top: 0, left: 0 },
            14: { src: '/imgs/building/highlights/frame_00_highlight_level_14.png', top: 0, left: 0 },
            13: { src: '/imgs/building/highlights/frame_00_highlight_level_13.png', top: 0, left: 0 },
            12: { src: '/imgs/building/highlights/frame_00_highlight_level_12.png', top: 0, left: 0 },
            11: { src: '/imgs/building/highlights/frame_00_highlight_level_11.png', top: 0, left: 0 },
            10: { src: '/imgs/building/highlights/frame_00_highlight_level_10.png', top: 0, left: 0 },
            9: { src: '/imgs/building/highlights/frame_00_highlight_level_09.png', top: 0, left: 0 },
            8: { src: '/imgs/building/highlights/frame_00_highlight_level_08.png', top: 0, left: 0 },
            7: { src: '/imgs/building/highlights/frame_00_highlight_level_07.png', top: 0, left: 0 },
            6: { src: '/imgs/building/highlights/frame_00_highlight_level_06.png', top: 0, left: 0 },
            5: { src: '/imgs/building/highlights/frame_00_highlight_level_05.png', top: 0, left: 0 },
            4: { src: '/imgs/building/highlights/frame_00_highlight_level_04.png', top: 0, left: 0 },
            3: { src: '/imgs/building/highlights/frame_00_highlight_level_03.png', top: 0, left: 0 },
            2: { src: '/imgs/building/highlights/frame_00_highlight_level_02.png', top: 0, left: 0 },
            1: { src: '/imgs/building/highlights/frame_00_highlight_level_01.png', top: 0, left: 0 },
        },
        18: {
            'HIGH-RISE': { src: '/imgs/building/highlights/frame_18_highlight_highrise.png', top: 0, left: 0 },
            'MID-RISE': { src: '/imgs/building/highlights/frame_18_highlight_midrise.png', top: 0, left: 0 },
            'LOW-RISE': { src: '/imgs/building/highlights/frame_18_highlight_lowrise.png', top: 0, left: 0 },
            'LOBBY': { src: '/imgs/building/highlights/frame_18_highlight_lobby.png', top: 0, left: 0 },
            'SIGNAGE': { src: '/imgs/building/highlights/frame_18_highlight_sign.png', top: 0, left: 0 },
            37: { src: '/imgs/building/highlights/frame_18_highlight_level_37.png', top: 0, left: 0 },
            36: { src: '/imgs/building/highlights/frame_18_highlight_level_36.png', top: 0, left: 0 },
            35: { src: '/imgs/building/highlights/frame_18_highlight_level_35.png', top: 0, left: 0 },
            34: { src: '/imgs/building/highlights/frame_18_highlight_level_34.png', top: 0, left: 0 },
            33: { src: '/imgs/building/highlights/frame_18_highlight_level_33.png', top: 0, left: 0 },
            32: { src: '/imgs/building/highlights/frame_18_highlight_level_32.png', top: 0, left: 0 },
            31: { src: '/imgs/building/highlights/frame_18_highlight_level_31.png', top: 0, left: 0 },
            30: { src: '/imgs/building/highlights/frame_18_highlight_level_30.png', top: 0, left: 0 },
            29: { src: '/imgs/building/highlights/frame_18_highlight_level_29.png', top: 0, left: 0 },
            28: { src: '/imgs/building/highlights/frame_18_highlight_level_28.png', top: 0, left: 0 },
            27: { src: '/imgs/building/highlights/frame_18_highlight_level_27.png', top: 0, left: 0 },
            26: { src: '/imgs/building/highlights/frame_18_highlight_level_26.png', top: 0, left: 0 },
            25: { src: '/imgs/building/highlights/frame_18_highlight_level_25.png', top: 0, left: 0 },
            24: { src: '/imgs/building/highlights/frame_18_highlight_level_24.png', top: 0, left: 0 },
            23: { src: '/imgs/building/highlights/frame_18_highlight_level_23.png', top: 0, left: 0 },
            22: { src: '/imgs/building/highlights/frame_18_highlight_level_22.png', top: 0, left: 0 },
            21: { src: '/imgs/building/highlights/frame_18_highlight_level_21.png', top: 0, left: 0 },
            20: { src: '/imgs/building/highlights/frame_18_highlight_level_20.png', top: 0, left: 0 },
            19: { src: '/imgs/building/highlights/frame_18_highlight_level_19.png', top: 0, left: 0 },
            18: { src: '/imgs/building/highlights/frame_18_highlight_level_18.png', top: 0, left: 0 },
            17: { src: '/imgs/building/highlights/frame_18_highlight_level_17.png', top: 0, left: 0 },
            16: { src: '/imgs/building/highlights/frame_18_highlight_level_16.png', top: 0, left: 0 },
            15: { src: '/imgs/building/highlights/frame_18_highlight_level_15.png', top: 0, left: 0 },
            14: { src: '/imgs/building/highlights/frame_18_highlight_level_14.png', top: 0, left: 0 },
            13: { src: '/imgs/building/highlights/frame_18_highlight_level_13.png', top: 0, left: 0 },
            12: { src: '/imgs/building/highlights/frame_18_highlight_level_12.png', top: 0, left: 0 },
            11: { src: '/imgs/building/highlights/frame_18_highlight_level_11.png', top: 0, left: 0 },
            10: { src: '/imgs/building/highlights/frame_18_highlight_level_10.png', top: 0, left: 0 },
            9: { src: '/imgs/building/highlights/frame_18_highlight_level_09.png', top: 0, left: 0 },
            8: { src: '/imgs/building/highlights/frame_18_highlight_level_08.png', top: 0, left: 0 },
            7: { src: '/imgs/building/highlights/frame_18_highlight_level_07.png', top: 0, left: 0 },
            6: { src: '/imgs/building/highlights/frame_18_highlight_level_06.png', top: 0, left: 0 },
            5: { src: '/imgs/building/highlights/frame_18_highlight_level_05.png', top: 0, left: 0 },
            4: { src: '/imgs/building/highlights/frame_18_highlight_level_04.png', top: 0, left: 0 },
            3: { src: '/imgs/building/highlights/frame_18_highlight_level_03.png', top: 0, left: 0 },
            2: { src: '/imgs/building/highlights/frame_18_highlight_level_02.png', top: 0, left: 0 },
            1: { src: '/imgs/building/highlights/frame_18_highlight_level_01.png', top: 0, left: 0 },
        },
        36: {
            'HIGH-RISE': { src: '/imgs/building/highlights/frame_36_highlight_highrise.png', top: 0, left: 0 },
            'MID-RISE': { src: '/imgs/building/highlights/frame_36_highlight_midrise.png', top: 0, left: 0 },
            'LOW-RISE': { src: '/imgs/building/highlights/frame_36_highlight_lowrise.png', top: 0, left: 0 },
            'LOBBY': { src: '/imgs/building/highlights/frame_36_highlight_lobby.png', top: 0, left: 0 },
            37: { src: '/imgs/building/highlights/frame_36_highlight_level_37.png', top: 0, left: 0 },
            36: { src: '/imgs/building/highlights/frame_36_highlight_level_36.png', top: 0, left: 0 },
            35: { src: '/imgs/building/highlights/frame_36_highlight_level_35.png', top: 0, left: 0 },
            34: { src: '/imgs/building/highlights/frame_36_highlight_level_34.png', top: 0, left: 0 },
            33: { src: '/imgs/building/highlights/frame_36_highlight_level_33.png', top: 0, left: 0 },
            32: { src: '/imgs/building/highlights/frame_36_highlight_level_32.png', top: 0, left: 0 },
            31: { src: '/imgs/building/highlights/frame_36_highlight_level_31.png', top: 0, left: 0 },
            30: { src: '/imgs/building/highlights/frame_36_highlight_level_30.png', top: 0, left: 0 },
            29: { src: '/imgs/building/highlights/frame_36_highlight_level_29.png', top: 0, left: 0 },
            28: { src: '/imgs/building/highlights/frame_36_highlight_level_28.png', top: 0, left: 0 },
            27: { src: '/imgs/building/highlights/frame_36_highlight_level_27.png', top: 0, left: 0 },
            26: { src: '/imgs/building/highlights/frame_36_highlight_level_26.png', top: 0, left: 0 },
            25: { src: '/imgs/building/highlights/frame_36_highlight_level_25.png', top: 0, left: 0 },
            24: { src: '/imgs/building/highlights/frame_36_highlight_level_24.png', top: 0, left: 0 },
            23: { src: '/imgs/building/highlights/frame_36_highlight_level_23.png', top: 0, left: 0 },
            22: { src: '/imgs/building/highlights/frame_36_highlight_level_22.png', top: 0, left: 0 },
            21: { src: '/imgs/building/highlights/frame_36_highlight_level_21.png', top: 0, left: 0 },
            20: { src: '/imgs/building/highlights/frame_36_highlight_level_20.png', top: 0, left: 0 },
            19: { src: '/imgs/building/highlights/frame_36_highlight_level_19.png', top: 0, left: 0 },
            18: { src: '/imgs/building/highlights/frame_36_highlight_level_18.png', top: 0, left: 0 },
            17: { src: '/imgs/building/highlights/frame_36_highlight_level_17.png', top: 0, left: 0 },
            16: { src: '/imgs/building/highlights/frame_36_highlight_level_16.png', top: 0, left: 0 },
            15: { src: '/imgs/building/highlights/frame_36_highlight_level_15.png', top: 0, left: 0 },
            14: { src: '/imgs/building/highlights/frame_36_highlight_level_14.png', top: 0, left: 0 },
            13: { src: '/imgs/building/highlights/frame_36_highlight_level_13.png', top: 0, left: 0 },
            12: { src: '/imgs/building/highlights/frame_36_highlight_level_12.png', top: 0, left: 0 },
            11: { src: '/imgs/building/highlights/frame_36_highlight_level_11.png', top: 0, left: 0 },
            10: { src: '/imgs/building/highlights/frame_36_highlight_level_10.png', top: 0, left: 0 },
            9: { src: '/imgs/building/highlights/frame_36_highlight_level_09.png', top: 0, left: 0 },
            8: { src: '/imgs/building/highlights/frame_36_highlight_level_08.png', top: 0, left: 0 },
            7: { src: '/imgs/building/highlights/frame_36_highlight_level_07.png', top: 0, left: 0 },
            6: { src: '/imgs/building/highlights/frame_36_highlight_level_06.png', top: 0, left: 0 },
            5: { src: '/imgs/building/highlights/frame_36_highlight_level_05.png', top: 0, left: 0 },
            4: { src: '/imgs/building/highlights/frame_36_highlight_level_04.png', top: 0, left: 0 },
            3: { src: '/imgs/building/highlights/frame_36_highlight_level_03.png', top: 0, left: 0 },
            2: { src: '/imgs/building/highlights/frame_36_highlight_level_02.png', top: 0, left: 0 },
            1: { src: '/imgs/building/highlights/frame_36_highlight_level_01.png', top: 0, left: 0 },
        },
        54: {
            'HIGH-RISE': { src: '/imgs/building/highlights/frame_54_highlight_highrise.png', top: 0, left: 0 },
            'MID-RISE': { src: '/imgs/building/highlights/frame_54_highlight_midrise.png', top: 0, left: 0 },
            'LOW-RISE': { src: '/imgs/building/highlights/frame_54_highlight_lowrise.png', top: 0, left: 0 },
            'LOBBY': { src: '/imgs/building/highlights/frame_54_highlight_lobby.png', top: 0, left: 0 },
            37: { src: '/imgs/building/highlights/frame_54_highlight_level_37.png', top: 0, left: 0 },
            36: { src: '/imgs/building/highlights/frame_54_highlight_level_36.png', top: 0, left: 0 },
            35: { src: '/imgs/building/highlights/frame_54_highlight_level_35.png', top: 0, left: 0 },
            34: { src: '/imgs/building/highlights/frame_54_highlight_level_34.png', top: 0, left: 0 },
            33: { src: '/imgs/building/highlights/frame_54_highlight_level_33.png', top: 0, left: 0 },
            32: { src: '/imgs/building/highlights/frame_54_highlight_level_32.png', top: 0, left: 0 },
            31: { src: '/imgs/building/highlights/frame_54_highlight_level_31.png', top: 0, left: 0 },
            30: { src: '/imgs/building/highlights/frame_54_highlight_level_30.png', top: 0, left: 0 },
            29: { src: '/imgs/building/highlights/frame_54_highlight_level_29.png', top: 0, left: 0 },
            28: { src: '/imgs/building/highlights/frame_54_highlight_level_28.png', top: 0, left: 0 },
            27: { src: '/imgs/building/highlights/frame_54_highlight_level_27.png', top: 0, left: 0 },
            26: { src: '/imgs/building/highlights/frame_54_highlight_level_26.png', top: 0, left: 0 },
            25: { src: '/imgs/building/highlights/frame_54_highlight_level_25.png', top: 0, left: 0 },
            24: { src: '/imgs/building/highlights/frame_54_highlight_level_24.png', top: 0, left: 0 },
            23: { src: '/imgs/building/highlights/frame_54_highlight_level_23.png', top: 0, left: 0 },
            22: { src: '/imgs/building/highlights/frame_54_highlight_level_22.png', top: 0, left: 0 },
            21: { src: '/imgs/building/highlights/frame_54_highlight_level_21.png', top: 0, left: 0 },
            20: { src: '/imgs/building/highlights/frame_54_highlight_level_20.png', top: 0, left: 0 },
            19: { src: '/imgs/building/highlights/frame_54_highlight_level_19.png', top: 0, left: 0 },
            18: { src: '/imgs/building/highlights/frame_54_highlight_level_18.png', top: 0, left: 0 },
            17: { src: '/imgs/building/highlights/frame_54_highlight_level_17.png', top: 0, left: 0 },
            16: { src: '/imgs/building/highlights/frame_54_highlight_level_16.png', top: 0, left: 0 },
            15: { src: '/imgs/building/highlights/frame_54_highlight_level_15.png', top: 0, left: 0 },
            14: { src: '/imgs/building/highlights/frame_54_highlight_level_14.png', top: 0, left: 0 },
            13: { src: '/imgs/building/highlights/frame_54_highlight_level_13.png', top: 0, left: 0 },
            12: { src: '/imgs/building/highlights/frame_54_highlight_level_12.png', top: 0, left: 0 },
            11: { src: '/imgs/building/highlights/frame_54_highlight_level_11.png', top: 0, left: 0 },
            10: { src: '/imgs/building/highlights/frame_54_highlight_level_10.png', top: 0, left: 0 },
            9: { src: '/imgs/building/highlights/frame_54_highlight_level_09.png', top: 0, left: 0 },
            8: { src: '/imgs/building/highlights/frame_54_highlight_level_08.png', top: 0, left: 0 },
            7: { src: '/imgs/building/highlights/frame_54_highlight_level_07.png', top: 0, left: 0 },
            6: { src: '/imgs/building/highlights/frame_54_highlight_level_06.png', top: 0, left: 0 },
            5: { src: '/imgs/building/highlights/frame_54_highlight_level_05.png', top: 0, left: 0 },
            4: { src: '/imgs/building/highlights/frame_54_highlight_level_04.png', top: 0, left: 0 },
            3: { src: '/imgs/building/highlights/frame_54_highlight_level_03.png', top: 0, left: 0 },
            2: { src: '/imgs/building/highlights/frame_54_highlight_level_02.png', top: 0, left: 0 },
            1: { src: '/imgs/building/highlights/frame_54_highlight_level_01.png', top: 0, left: 0 },
        },
    },
}
