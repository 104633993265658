
import React from 'react';
import ViewSplitPage from '../../components/splitpage/ViewSplitPage';
import './stylesBenefits.scss';

export default function ViewWellness () {
    
    const image = <img className='image benefits--image' src={ process.env.PUBLIC_URL + '/imgs/building-highlights/wellness.jpg' } alt='' />;

    const page = (
        <div className='copy-container benefits--copy-container'>
            <h1 className='title benefits--title'>A Destination For Wellness</h1>
            <p className='statement benefits--statement'>Hotel-style end-of-trip facilities</p>
            <p className='statement benefits--statement'>Wellness centre for classes and consultations</p>
            <p className='statement benefits--statement'>Secure bicycle parking</p>
            <p className='statement benefits--statement'>Lockers for daily or long-term use</p>
            <p className='statement benefits--statement'>Clothes drying services</p>
            <p className='statement benefits--statement'>Inclusive facilities</p>
        </div>
    );
    
    return (
        <ViewSplitPage 
            className='benefits--transform-container benefits--split-page'
            pageLeft={ image }
            pageRight={ page } />
    );
}