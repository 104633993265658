import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import LoadContainer from '../../components/LoadContainer';
import { selectOverlay } from '../../components/overlay';
import './stylesVision.scss';


function ViewVision ( { selectOverlay } ) {
    return (
        <LoadContainer>
            <img src={ process.env.PUBLIC_URL + '/imgs/vision/vision_bkg.jpg' } width='1920' height='1080' alt='' />
            <button className='vision--button__left-third' 
                onClick={ () => selectOverlay( { type:'video', src: 'https://vimeo.com/754161643', options: { fullScreen: true } } ) }></button>
            <button className='vision--button__center-third' 
                onClick={ () => selectOverlay( { type:'video', src: 'https://vimeo.com/738476839/b5dc9d5c8a', options: { fullScreen: true } } ) }></button>
            
            <NavLink className='vision--button__right-third' to='/virtual-tour'></NavLink>
        </LoadContainer>
    );
}

const mapStateToProps = (state) => ({
  
});

const mapDispatchToProps = (dispatch) => ({
    selectOverlay: (data) => dispatch(selectOverlay(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewVision);