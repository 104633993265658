import React from 'react';
import ViewSplitPage from '../../components/splitpage/ViewSplitPage';
import './stylesBenefits.scss';

export default function ViewServices () {
    
    const image = <img className='image benefits--image' src={ process.env.PUBLIC_URL + '/imgs/building-highlights/services.jpg' } alt='' />;

    const page = (
        <div className='copy-container benefits--copy-container'>
            <h1 className='title benefits--title'>Bespoke Services And Experiences</h1>
            <p className='statement benefits--statement'>Hotel-style concierge and amenity</p>
            <p className='statement benefits--statement'>Curated services and experiences</p>
            <p className='statement benefits--statement'>Engaged community supported by wider Collins Street precinct</p>
            <p className='statement benefits--statement'>Calendar of building events and activations</p>
        </div>
    );

    return (
        <ViewSplitPage
            className='benefits--transform-container benefits--split-page'
            pageLeft={ image }
            pageRight={ page } />
    );
}