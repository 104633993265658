import React from 'react';
import ViewSplitPage from '../../components/splitpage/ViewSplitPage';
import './stylesBenefits.scss';

export default function ViewDexusPlace () {
    
    const image = <img className='image benefits--image' src={ process.env.PUBLIC_URL + '/imgs/building-highlights/dexus-place.jpg' } alt='' />;

    const page = (
        <div className='copy-container benefits--copy-container'>
            <h1 className='title benefits--title'>Dexus Place</h1>
            <p className='statement benefits--statement'>Extension of your workspace</p>
            <p className='statement benefits--statement'>Meeting and project spaces</p>
            <p className='statement benefits--statement'>Virtual offices</p>
            <p className='statement benefits--statement'>Remote working solutions</p>
            <p className='statement benefits--statement'>Corporate event space</p>
            <p className='statement benefits--statement'>Video Conferencing</p>
            <p className='statement benefits--statement'>Onsite catering</p>
        </div>
    );
    
    return (
        <ViewSplitPage
            className='benefits--transform-container benefits--split-page'
            pageLeft={ page }
            pageRight={ image } />
    );
}