const data = [
    {
        video: '/imgs/project-pillars/Rarity.mp4',
        pageContent: (
            <div className='project-pillars--copy-container'>
                <h1 className='project-pillars--title'>Rarity</h1>
                <p className='project-pillars--statement'>A development of this nature may never come again to the Paris End of Collins Street. Designed by renowned architects Bates Smart, Sixty Collins is a world-class, Premium-Grade office building, the final opportunity to make your mark in this strategic location.</p>
            </div>
        ),
        reverse: false
    },
    {
        video: '/imgs/project-pillars/Aspiration.mp4',
        pageContent: (
            <div className='project-pillars--copy-container'>
                <h1 className='project-pillars--title'>Aspiration</h1>
                <p className='project-pillars--statement'>Progressive and future-focused, Sixty Collins is Melbourne's new pinnacle address. Surrounded by icons of industry and Melbourne’s corporate elite, it will drive new ambition and the pursuit of excellence.</p>
            </div>
        ),
        reverse: true
    },
    {
        video: '/imgs/project-pillars/Evolution.mp4',
        pageContent: (
            <div className='project-pillars--copy-container'>
                <h1 className='project-pillars--title'>Evolution</h1>
                <p className='project-pillars--statement'>As hybrid working becomes more common, workplaces need to evolve to encourage employees back into the office. Sixty Collins will cater to changing workplace requirements, to attract and retain the next generation of exceptional talent.</p>
            </div>
        ),
        reverse: false
    },
    {
        video: '/imgs/project-pillars/Atmosphere.mp4',
        pageContent: (
            <div className='project-pillars--copy-container__atmosphere'>
                <h1 className='project-pillars--title'>Atmosphere</h1>
                <p className='project-pillars--statement'>A feeling of inspiration and excitement leaving a lasting impression. Curated retail experiences will further activate Collins and Exhibition Streets, expanding Melbourne's most prestigious precinct.</p>
            </div>
        ),
        reverse: true
    },
    {
        video: '/imgs/project-pillars/Curated.mp4',
        pageContent: (
            <div className='project-pillars--copy-container'>
                <h1 className='project-pillars--title'>Curated</h1>
                <p className='project-pillars--statement'>High quality finishes and premium amenity elevate every moment in this exceptional building. Every detail has been carefully considered for the wellbeing of its occupants.</p>
            </div>
        ),
        reverse: false
    },
];

export default data;