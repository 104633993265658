
import ViewHome from './home/ViewHome';
import ViewVision from './vision/ViewVision';
import ViewVirtualTour from './vision/ViewVirtualTour';
import ConnectViewResidences from './building/ConnectViewResidences';
import ViewGallery from './gallery/ViewGallery';

import ViewBuildingHighlights from './benefits/ViewBuildingHighlights';
import ViewArrival from './benefits/ViewArrival';
import ViewWorkspace from './benefits/ViewWorkspace';
import ViewTechnology from './benefits/ViewTechnology';
import ViewSustainability from './benefits/ViewSustainability';
import ViewWellness from './benefits/ViewWellness';
import ViewServices from './benefits/ViewServices';
import ViewPrecinct from './benefits/ViewPrecinct';
import ViewDexusPlace from './benefits/ViewDexusPlace';
import ViewProjectPillars from './benefits/ViewProjectPillars';
import ViewTourAll from './tour/ViewTourAll';
import ViewTeamDexus from './team/ViewTeamDexus';
import ViewTeamBatesSmart from './team/ViewTeamBatesSmart';
import ViewMultipage from '../components/multipage/ViewMultipage';
import dataLocation from '../data/locations';

export const dataRoutes = [
    {
        title: 'Vision',
        path: '/vision',
        element: <ViewVision/>
    },
    {
        title: 'Virtual Tour',
        path: '/virtual-tour',
        inMenu: false,
        element: <ViewVirtualTour/>
    },
    {
        title: 'Location',
        path: '/location',
        element: <ViewMultipage data={ dataLocation } />
    },
    {
        title: 'Benefits',
        routes: [
            {
                title: 'PROJECT PILLARS',
                path: '/project-pillars',
                element: <ViewProjectPillars />
            },
            {
                title: 'BUILDING HIGHLIGHTS',
                path: '/building-highlights',
                element: <ViewBuildingHighlights />,
                secondaryMenu: true,
                routes: [
                    {
                        title: 'Arrival',
                        path: '/building-highlights/arrival',
                        element: <ViewArrival />,
                    },
                    {
                        title: 'Workspace',
                        path: '/building-highlights/workspace',
                        element: <ViewWorkspace />,
                    },
                    {
                        title: 'Technology',
                        path: '/building-highlights/technology',
                        element: <ViewTechnology />,
                    },
                    {
                        title: 'Sustainability',
                        path: '/building-highlights/sustainability',
                        element: <ViewSustainability />,
                    },
                    {
                        title: 'Wellness',
                        path: '/building-highlights/wellness',
                        element: <ViewWellness />,
                    },
                    {
                        title: 'Services',
                        path: '/building-highlights/sevices',
                        element: <ViewServices />,
                    },
                    {
                        title: 'Precinct',
                        path: '/building-highlights/precinct',
                        element: <ViewPrecinct />,
                    },
                    {
                        title: 'Dexus Place',
                        path: '/building-highlights/dexus-place',
                        element: <ViewDexusPlace />,
                    },
                ]
            }
        ]
    },
    {
        title: 'Building',
        path: '/building',
        element: <ConnectViewResidences />
    },
    {
        title: 'Gallery',
        routes: [
            {
                title: 'CGIs',
                path: '/gallery-cgi',
                element: <ViewGallery/>
            },
            {
                title: 'VIEW PANORAMAS',
                path: '/panoramas',
                element: <ViewTourAll/>
            }
        ]
    },
    {
        title: 'Team',
        path: '/team-dexus',
        secondaryMenu: true,
        routes: [
            {
                title: 'Dexus',
                path: '/team-dexus',
                element: <ViewTeamDexus />
            },
            {
                title: 'Bates Smart',
                path: '/team-bates-smart',
                element: <ViewTeamBatesSmart />
            }
        ]
    },
    {
        title: 'Home',
        path: '/',
        element: <ViewHome />
    }
];