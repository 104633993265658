
import React from 'react';
import ViewSplitPage from '../../components/splitpage/ViewSplitPage';
import './stylesBenefits.scss';

export default function ViewSustainability () {
    
    const image = <img className='image benefits--image' src={ process.env.PUBLIC_URL + '/imgs/building-highlights/sustainability.jpg' } alt='' />;

    const page = (
        <div className='copy-container benefits--copy-container'>
            <h1 className='title benefits--title'>Sustainable Design Principles</h1>
            <p className='statement benefits--statement'>Targeting 6-Star Green Star</p>
            <p className='statement benefits--statement'>Targeting 5.5-Star Nabers Energy</p>
            <p className='statement benefits--statement'>Platinum Well building rating</p>
            <p className='statement benefits--statement'>High levels and evenly distributed daylight</p>
            <p className='statement benefits--statement'>Triple-glazed fa&ccedil;ade to provide high level of thermal comfort</p>
            <p className='statement benefits--statement'>Energy efficient lighting</p>
        </div>
    );
    
    return (
        <ViewSplitPage
            className='benefits--transform-container benefits--split-page'
            pageLeft={ page }
            pageRight={ image } />
    );
}