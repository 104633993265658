
import React from 'react';
import './stylesBenefits.scss';


export default function ViewBuildingHighlights () {

    const highlights = [];
    const dataHighlights = [
        {
            title: 'Corner',
            statement: 'of Collins and Exhibition Streets',
        },
        {
            title: '37 Floors',
            statement: 'of Premium-Grade workspaces totaling 43,000sqm',
        },
        {
            title: 'Premium',
            statement: 'arrival experience via multiple lobby entrances',
        },
        {
            title: 'Signage',
            statement: 'opportunity on prominent Collins Street',
        },
        {
            title: 'Curated',
            statement: 'services and amenity',
        },
        {
            title: '6-Star',
            statement: 'Green Star target',
        },
    ];

    for ( let idx=0; idx<dataHighlights.length; idx++ ) {
        const highlight = dataHighlights[idx];
        highlights.push(
            <div key={ idx } className='building-highlights--highlight'>
                <h2 className='title benefits--title'>{ highlight.title }</h2>
                <p className='statement benefits--statement'>{ highlight.statement }</p>
            </div>
        );
    }
    
    return (
        <div className='benefits--transform-container building-highlights'>
            { highlights }
        </div>
    );
}