
import React from 'react';
import ViewSplitPage from '../../components/splitpage/ViewSplitPage';
import './stylesBenefits.scss';

export default function ViewTechnology () {
    
    
    const image = <img className='image benefits--image' src={ process.env.PUBLIC_URL + '/imgs/building-highlights/technology.jpg' } alt='' />;

    const page = (
        <div className='copy-container benefits--copy-container'>
            <h1 className='title benefits--title'>Smart Building Technology</h1>
            <p className='statement benefits--statement'>Access control system</p>
            <p className='statement benefits--statement'>Integrated visitor management</p>
            <p className='statement benefits--statement'>Space usage analytics</p>
            <p className='statement benefits--statement'>Smart lighting</p>
            <p className='statement benefits--statement'>Wifi &amp; internet services</p>
            <p className='statement benefits--statement'>Destination controlled lifts</p>
            <p className='statement benefits--statement'>5G distributed antenna systems</p>
        </div>
    );
    
    return (
        <ViewSplitPage 
            className='benefits--transform-container benefits--split-page'
            pageLeft={ page }
            pageRight={ image } />
    );
}